// color palette
$color-black: #000000;
$color-white: #ffffff;
$color-graphene: #004F59;
$color-graphene-light: #004F5A;
$color-lime: #B8F4A1;
$color-spakr: #FF4713;
$color-compliant-spark: #E62200;
$color-warm-grey: #D6D1CA;
$color-light-warm-grey: #F5F5F5;
$color-coalescent: #131E29;
$color-yellow: #F4CA40;


//fonts
@font-face {
  font-family: 'Averta';
  src: url('../font/Averta-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('../font/Averta-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Averta';
  src: url('../font/Averta-Semibold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('../font/Averta-SemiboldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Averta';
  src: url('../font/Averta-Bold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('../font/Averta-BoldItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Averta';
  src: url('../font/Averta-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('../font/Averta-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Averta-CY';
  src: url("../font/Averta-CY.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

//transition time
$transition-time: 200ms;

//font size
$global-font-size: 16;

// others
$wrapper-max-width: 1200px;
$wrapper-wide-max-width: 1600px;
