@use './mixing'as mix;
@use 'include-media' as media;
@use 'variables'as var;

//global font
* {
  font-family: 'Averta', sans-serif;
}

html {
    scroll-behavior: smooth;
}

body {
    padding:
    env(safe-area-inset-top)
    env(safe-area-inset-right)
    env(safe-area-inset-bottom)
    env(safe-area-inset-left);
}

.wrapper {
    @include mix.toWrap();
}

//typography
h1 {
  letter-spacing: -0.02em;
  font-size: 2.5rem;//40px
  font-weight: 700;
  line-height: 2.5rem;//40px

  @include media.media('<tablet') {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
}

h2 {
  letter-spacing: -0.02em;
  font-size: 2.125rem;//34px
  font-weight: 700;
  line-height: 2.5rem;//40px

  @include media.media('<tablet') {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.01em;
  }
}

h3 {
  letter-spacing: -0.02em;
  font-size: 1.75rem;//28px
  font-weight: 700;
  line-height: 2rem;//32px

  @include media.media('<tablet') {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.01em;
  }
}

h4 {
  letter-spacing: -0.01em;
  font-size: 1.5rem;//24px
  font-weight: 700;
  line-height: 1.75rem;//28px
}

h5 {
  letter-spacing: -0.01em;
  font-size: 1.375rem;//22px;
  font-weight: 700;
  line-height: 1.75rem;//28px
}

p {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.01em;

  @include media.media('<tablet') {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: unset;
  }
}

a {
  color: var.$color-graphene;
}

hr {
  border-top: 1px solid var.$color-graphene;
  opacity: 0.25;
}

.primary-cta-label {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
}

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone
@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }
  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

.adyen-checkout__button--pay {
  /* Pay button */
  background: var.$color-spakr;
  border-radius: 4px;
  color: var.$color-coalescent;

  font-size: 20px;
  font-weight: 700;
  line-height: 22px;

  &:hover {
    background: var.$color-compliant-spark;
  }
}
