* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  
  &:active,
  :focus {
    outline: none !important;
  }
}

ul, ol {
  list-style: none;
}

.mat-mdc-dialog-container,
.mdc-dialog__surface {
  border-radius: 8px !important;
}